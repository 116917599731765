import { dateValidator } from "../../../../utility/clock";
import Axios from "axios";
import { message } from "antd";
import db from "../../../../database";
import * as Sentry from "@sentry/react";

export const redeemLoyality = async (paymentMethod, value, cart, setCart, setAmount, setSelectedPaymentMethod, setAuthTokens, completePayment) => {
  const serverUrl = process.env.REACT_APP_serverUrl;
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  let loyaltyData = await db.loyalityData.toArray();

  let updatedCart = { ...cart };
  let amount = 0;
  let conversionValue = 0;
  let redemptionValue = 0;

  if (value <= updatedCart.customer.retlLoyaltyBalance) {
    loyaltyData.forEach((loyalty) => {
      if (loyalty.loyaltylevelId === updatedCart.customer.loyaltyLevel.cwrLoyaltyLevelId) {
        loyalty.loyaltyRedemption.forEach((redeemItem) => {
          if (dateValidator(redeemItem.startDate, redeemItem.endDate) && redeemItem.minPurchase <= updatedCart.total && redeemItem.pointsRequired <= parseFloat(value)) {
            conversionValue = updatedCart.customer.retlLoyaltyBalance - parseFloat(value);
            amount = redeemItem.redemptionValue * value;
            redemptionValue = redeemItem.redemptionValue;

            if (updatedCart.total < amount) {
              value = updatedCart.total === amount ? value : parseFloat((updatedCart.total / redeemItem.redemptionValue).toFixed(2));
            }
          }
        });
      }
    });
  }

  if (updatedCart.customer.loyaltyLevel.cwrLoyaltyLevelId && amount > 0 && amount <= updatedCart.total - updatedCart.paid) {
    if (value <= parseFloat(updatedCart.customer.retlLoyaltyBalance)) {
      updatedCart.customer.retlLoyaltyBalance = parseFloat(conversionValue.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision));
      updatedCart.customer.redeemptionValue = parseFloat(redemptionValue.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision));

      try {
        const loyaltyResponse = await Axios({
          url: serverUrl,
          method: "POST",
          data: {
            query: `mutation {
                      upsertLoyaltyTransaction(transaction: [{
                          csBunitId: "${tillData.tillAccess.csBunit.csBunitId}"
                          loyaltyPoints: ${value}
                          b2cCustomerId: "${updatedCart.customer.cwrCustomerId}"
                          referenceId: "${updatedCart.referenceId}"
                        }]) {
                        status 
                        message
                      }
                    }`,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `${setAuthTokens}`,
          },
        });

        if (loyaltyResponse?.data?.data?.upsertLoyaltyTransaction?.status) {
          let rem = parseInt(updatedCart.redemptionPoints) + parseFloat(value);
          updatedCart.redemptionPoints = rem;
          completePayment(paymentMethod, amount, updatedCart, setCart, setAmount, setSelectedPaymentMethod);
        }
      } catch (err) {
        message.error("Error processing loyalty points redemption. Please try again later. If the problem persists, please contact technical support.");
        Sentry.captureException(err);
        Sentry.captureException(new Error("Loyalty payment Error"), {
          extra: JSON.stringify({
            csBunitId: tillData.tillAccess.csBunit.csBunitId,
            loyaltyPoints: value,
            b2cCustomerId: updatedCart.customer.cwrCustomerId,
            referenceId: updatedCart.referenceId,
          }),
        });
      }
    } else {
      message.error("Entered loyalty points exceed your available balance. Please enter valid points.");
    }
  } else if (amount > updatedCart.total - updatedCart.paid) {
    message.warning("It is not possible to add Overpayment with this Payment Method");
  }
};
