import Axios from "axios";
import { message } from "antd";

export const stock = async ({ posConfig, cart, authHeaders, tillData, serverUrl, setCart, setSelectedProductInCart, setTempCart, handleCartTotalClick, qty, setProductStock }) => {
  if (posConfig?.enableStockCheck === "N" || !posConfig?.enableStockCheck) {
    handleCartTotalClick();
    return true;
  }

  const buildCartProducts = cart.items.map(({ value, weight }) => `{ productId: "${value}", orderQty: ${weight} }`).join(", ");
  const accessToken = authHeaders?.access_token;

  const query = `
      query {
        verifyStock(order: {
          orderNo: "${cart.documentno}"
          tillId: "${tillData.tillAccess.cwrTill.searchKey}"
          cartItems: [${buildCartProducts}]
        }) {
          orderNo
          tillId
          cartItems {
            productId
            orderQty
            availabilityStatus
            availableQty
          }
        }
      }
    `;

  try {
    const response = await Axios({
      url: serverUrl,
      method: "POST",
      data: { query },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${accessToken}`,
      },
    });

    if (response.status === 200) {
      const { cartItems } = response.data.data.verifyStock;

      const hasInsufficientStock = cart.items.some((item) => {
        const resItem = cartItems.find((resItem) => resItem.productId === item.value);
        return resItem && resItem.availableQty < item.weight;
      });

      if (hasInsufficientStock && tillData.tillAccess.csBunit.allowNegativeStock === "N") {
        setProductStock(true);
        return false;
      }

      return true;
    }
  } catch (error) {
    console.error("Error checking stock:", error);
    return false;
  }
};
