import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col, Input, Button, Menu } from "antd";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { clock } from "../../utility/clock";
import { timeStamp } from "../../utility/timestamp";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Scrollbars } from "react-custom-scrollbars";
import MenuIcon from "../../assets/images/icon.png";
import db from "../../database";
import data from "../../constants/opentillClosetill.json";
import "../style.css";
import moment from "moment";

const CloseTill = () => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const currenciesList = tillData.tillAccess.csBunit.currencies;
  const paymentMethodList = tillData.tillAccess.csBunit.paymentMethodList;
  const tillSession = JSON.parse(localStorage.getItem("tillSession"));
  const tillaccess = JSON.parse(tillData?.tillAccess?.userAccessController);
  const tillLayout = parseInt(tillaccess?.layout === null || undefined ? 1 : tillaccess?.layout);
  const sessionId = tillSession.tillSessionId;
  const history = useHistory();

  const [currencyType, setCurrencyType] = useState({
    currSymbolLeft: "₹",
    currSymbolRight: "",
    stdPrecision: 2,
  });
  const [cashExpectedAmount, setCashExpectedAmount] = useState(0);
  const [cashTransactionCount, setCashTransactionCount] = useState(0);
  const [allPaymentsData, setAllPaymentsData] = useState([]);
  const [totalOrdersCount, setTotalOrdersCount] = useState(0);
  const [totalOpeningAmount, setTotalOpeningAmount] = useState(0);
  const [cashSaleAmount, setCashSaleAmount] = useState(0);
  const [cashReturnAmount, setCashReturnAmount] = useState(0);
  const [cashValues, setCashValues] = useState({
    cashIn: 0,
    cashOut: 0,
    pettCashIn: 0,
    pettCashOut: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      // Ensure tillSession exists before proceeding
      if (!tillSession) {
        history.push("/"); // Redirect if data is missing
        return;
      }

      // Default object to store values
      let obj = { cashIn: 0, cashOut: 0, pettCashIn: 0, pettCashOut: 0 };

      // Fetch cashIn/cashOut data
      let cashDetails = await db.cashInCashOut.toArray();
      cashDetails.forEach((item) => {
        if (item.type === "cashIn") {
          obj.cashIn += parseFloat(item.amount || 0);
        } else if (item.type === "cashOut") {
          obj.cashOut += parseFloat(item.amount || 0);
        }
        if (item.type === "pettyCashIn") {
          obj.pettCashIn += parseFloat(item.amount || 0);
        }
        if (item.type === "pettyCashOut") {
          obj.pettCashOut += parseFloat(item.amount || 0);
        }
      });
      setCashValues(obj);

      // Fetch currency details
      db.tillRegistrations.toArray().then((reg) => {
        if (reg.length > 0 && reg[0].tillAccess?.[0]?.csBunit?.csCurrency) {
          const curList = reg[0].tillAccess[0].csBunit.csCurrency;
          setCurrencyType({
            currSymbolLeft: curList.symbolrightside ? "" : curList.currSymbol,
            currSymbolRight: curList.symbolrightside ? curList.currSymbol : "",
            stdPrecision: curList.stdPrecision || 2, // Default precision if not provided
          });
        }
      });

      // Fetch and process orders related to the session
      const newPaymentsList = [];
      const orders = await db.orders.where("tillSessionId").equals(sessionId).toArray();

      paymentMethodList.forEach((payment) => {
        let currPaymentAmount = 0;
        let transactionCount = 0;
        let currPaymentReturn = 0;

        orders.forEach((order) => {
          const orderPayments = order.payments || [];
          orderPayments.forEach((orderPayment) => {
            if (payment.name.toLowerCase() === orderPayment.name.toLowerCase()) {
              if (order.total > 0) {
                currPaymentAmount += parseFloat(orderPayment.amount || 0);
              } else {
                currPaymentReturn += parseFloat(Math.abs(orderPayment.amount || 0));
              }
              transactionCount += 1;
            }
          });
        });

        // Update payment method data
        payment.expectedAmount = currPaymentAmount;
        payment.amount = 0;
        payment.paymentReturn = currPaymentReturn;
        payment.difference = Math.abs(currPaymentReturn) - currPaymentAmount;
        payment.transactionCount = transactionCount;
        newPaymentsList.push(payment);
      });

      setAllPaymentsData([...newPaymentsList]);

      // Calculate and set cash data
      const cashIndex = newPaymentsList.findIndex((p) => p.name.toLowerCase() === "cash");
      if (cashIndex !== -1) {
        const cashAmount = parseFloat(newPaymentsList[cashIndex].expectedAmount || 0);
        const returnAmount = parseFloat(newPaymentsList[cashIndex].paymentReturn || 0);
        const cashTrxCount = parseFloat(newPaymentsList[cashIndex].transactionCount || 0);
        const openAmount = JSON.parse(localStorage.getItem("tillSession"))?.totalOpeningAmount || 0;

        setTotalOpeningAmount(openAmount);
        setTotalOrdersCount(orders.length);
        setCashSaleAmount(cashAmount);
        setCashReturnAmount(returnAmount);

        const expAmt =
          tillData?.tillAccess?.csBunit?.incDayOpening === "N"
            ? cashAmount - returnAmount + obj.cashIn + obj.pettCashIn - obj.pettCashOut - obj.cashOut
            : cashAmount + openAmount - returnAmount + obj.cashIn + obj.pettCashIn - obj.pettCashOut - obj.cashOut;

        setCashExpectedAmount(expAmt);
        setCashTransactionCount(cashTrxCount);
      }
    };
    fetchData().catch((error) => {
      console.error("Error during data fetch:", error);
    });
  }, []);

  const [denominations, setDenominations] = useState([]);

  useEffect(() => {
    if (tillSession?.closingCashDenominations) {
      setDenominations(tillSession.closingCashDenominations);
    } else {
      const modifiedCurrenciesList = currenciesList[0].denominations?.map((denomItem, denomIndex) => ({
        ...denomItem,
        count: 0,
        amount: 0,
      }));

      const sortedDenominations = modifiedCurrenciesList?.sort((a, b) => a.value - b.value);
      console.log("sortedDenominations", sortedDenominations);
      setDenominations([...sortedDenominations]);
    }
  }, []);

  useEffect(() => {
    if (tillSession?.closingCash) {
      setTotalAmount(tillSession.closingCash);
    }
  }, []);

  const currentDate = new Date().toLocaleDateString();
  const [displayClock, setDisplayClock] = useState("");
  useEffect(() => {
    setDisplayClock(clock());
    const timerId = setInterval(() => setDisplayClock(clock()), 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);

  const [totalAmount, setTotalAmount] = useState(0);

  const onFieldInput = (e) => {
    const { name, value } = e.target;
    const denominationName = parseInt(name);
    const denominationValue = parseInt(value);
    const denominationAmount = denominationName * denominationValue;
    const di = denominations.findIndex((d) => d.value === denominationName);
    denominations[di].count = isNaN(denominationValue) ? "" : denominationValue;
    denominations[di].amount = isNaN(denominationAmount) ? 0 : denominationAmount;
    let total = 0;
    denominations.forEach((denom) => {
      total += denom.amount;
    });
    setTotalAmount(total);
    setDenominations([...denominations]);
  };

  const onFieldOut = (e) => {
    if (e.target.value === "") {
      const di = denominations.findIndex((d) => d.value === parseInt(e.target.name));
      denominations[di].count = 0;
      setDenominations([...denominations]);
    }
  };

  const onFieldButton = (field, action) => {
    if (action === "plus") denominations[field].count += 1;
    if (action === "minus" && denominations[field].count > 0) denominations[field].count -= 1;
    const denominationAmount = denominations[field].value * denominations[field].count;
    const denominationAmountValue = isNaN(denominationAmount) ? 0 : denominationAmount;
    denominations[field].amount = denominationAmountValue;
    let total = 0;
    denominations.forEach((denom) => {
      total += denom.amount;
    });
    setTotalAmount(total);
    setDenominations([...denominations]);
  };

  const confirmBtnRef = useRef(null);
  const cancelBtnRef = useRef(null);

  const excuteCloseTill = async () => {
    let obj = { cashIn: 0, cashOut: 0, pettCashIn: 0, pettCashOut: 0 };

    try {
      const tillEvent = await db.tillEvents.where("tillSessionId").equals(sessionId).toArray();

      if (tillEvent.length > 0 && tillEvent[0]?.cashInOutData) {
        setCashValues(tillEvent[0].cashInOutData);
        obj = tillEvent[0].cashInOutData;
      }

      confirmBtnRef.current.disabled = true;
      cancelBtnRef.current.disabled = true;

      const updatedTillSession = { ...tillSession };
      const updatedAllPaymentsData = [...allPaymentsData];
      const aPi = updatedAllPaymentsData.findIndex((apd) => apd.name.toLowerCase() === "cash");

      if (aPi === -1) {
        console.error("Cash payment data not found.");
        return;
      }

      let updatedAmount = 0;
      if (tillData?.tillAccess?.csBunit?.incDayOpening === "N") {
        updatedAmount = parseFloat(updatedAllPaymentsData[aPi].expectedAmount) + obj.cashIn - obj.cashOut + obj.pettCashIn - obj.pettCashOut;
      } else {
        updatedAmount = parseFloat(updatedAllPaymentsData[aPi].expectedAmount) + parseFloat(totalOpeningAmount || 0) + obj.cashIn - obj.cashOut + obj.pettCashIn - obj.pettCashOut;
      }

      updatedAllPaymentsData[aPi].expectedAmount = updatedAmount;
      updatedAllPaymentsData[aPi].amount = totalAmount || 0;
      updatedAllPaymentsData[aPi].actualAmount = totalAmount || 0;
      updatedAllPaymentsData[aPi].cashSaleAmount = parseFloat(cashSaleAmount?.toFixed(tillData.tillAccess.csBunit.currencies[0]?.prcPrecision || 2));
      updatedAllPaymentsData[aPi].difference = parseFloat(
        (totalAmount - parseFloat(updatedAmount - updatedAllPaymentsData[aPi].paymentReturn)).toFixed(tillData.tillAccess.csBunit.currencies[0]?.prcPrecision || 2)
      );

      updatedTillSession.closingTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      updatedTillSession.closingCash = totalAmount || 0;
      updatedTillSession.closingCashDenominations = denominations || [];
      updatedTillSession.allPaymentsData = updatedAllPaymentsData;
      updatedTillSession.totalOrdersCount = totalOrdersCount || 0;

      await db.tillEvents.where("tillSessionId").equals(updatedTillSession.tillSessionId).modify(updatedTillSession);

      localStorage.setItem("tillSession", JSON.stringify(updatedTillSession));

      confirmBtnRef.current.disabled = false;
      cancelBtnRef.current.disabled = false;

      history.push("/close-till-all");
    } catch (error) {
      console.error("Error executing close till operation:", error);
      confirmBtnRef.current.disabled = false;
      cancelBtnRef.current.disabled = false;
    }
  };

  let reconstructedObject;

  const rawtillAccessMeta = tillData?.tillAccess?.tillAccessMeta;
  if (rawtillAccessMeta) {
    const rawDataArray = tillData?.tillAccess?.tillAccessMeta;

    const closeTillObject = rawDataArray.find((item) => item.key === "Close Till Denominations");

    const closeTillValue = closeTillObject?.value;

    if (closeTillValue !== undefined) {
      const cleanedData = closeTillValue.replace(/[{}"]/g, "");
      const keyValuePairs = cleanedData.split(",");

      reconstructedObject = {};
      keyValuePairs.forEach((pair) => {
        if (pair.includes(":")) {
          const [key, value] = pair.split(":");

          // Check if both key and value are not undefined before trimming
          if (key !== undefined && value !== undefined) {
            reconstructedObject[key.trim()] = value.trim();
          }
        }
      });
    } else {
      // Handle the case where closeTillValue is undefined
      console.error("closeTillValue is undefined");
    }
  }

  return (
    <div style={data.openTill.mainContainer}>
      <Row style={{ height: "6vh", padding: "1% 0 0 1%" }}>
        <Col span={1} style={{ display: "flex", alignItems: "center", textAlign: "left" }}>
          <img src={MenuIcon} style={data.openTill.header.menuIcon} alt="" />
        </Col>
        <Col span={23} style={{ fontSize: "1vw", paddingTop: "0.6vh" }}>
          <label
            style={{
              fontSize: "1.8em",
              color: "#0F0718",
              letterSpacing: "0.36px",
              opacity: 1,
              fontWeight: "500",
              textAlign: "center",
              paddingLeft: "1vw",
              marginTop: "0.5vh",
            }}
          >
            Close Till
          </label>
        </Col>
      </Row>

      <div style={data.openTill.mainCardContainer}>
        <div
          style={{
            padding: "1vw",
            display: "flex",
            paddingBottom: "0",
            fontSize: "1vw",
          }}
        >
          <div style={{ borderRadius: "3px", width: "68.5%" }}>
            <div key="z" style={data.openTill.mainCardFirst}>
              <Row
                style={{
                  padding: "1% 0%",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.09)",
                }}
              >
                <Col span={7} style={data.openTill.colHead}>
                  {/* <strong>Denomination</strong> */}
                  <span>Denomination</span>
                </Col>
                <Col span={10} style={data.openTill.colHead}>
                  {/* <strong>Count</strong> */}
                  <span>Count</span>
                </Col>
                <Col span={7} style={data.openTill.colHead}>
                  {/* <strong>Total Amount</strong> */}
                  <span>Total Amount</span>
                </Col>
              </Row>
              <Row>
                <Scrollbars className="closeTillScroll">
                  {denominations.map((denom, index) => (
                    <Row>
                      <Col span={7} style={data.openTill.colAlign}>
                        <Input tabIndex={-1} readOnly={true} style={data.openTill.currency} value={denom.value} />
                      </Col>
                      <Col span={10} style={data.openTill.colAlign}>
                        <div style={data.openTill.calInputDiv}>
                          <MinusOutlined style={data.openTill.incInpt} type="minus" onClick={() => onFieldButton(index, "minus")} />
                          <Form.Item style={data.openTill.countFormitem}>
                            <Input name={`${denom.value}`} value={denom.count} style={data.openTill.calInput} onChange={onFieldInput} onBlur={onFieldOut} />
                          </Form.Item>
                          <PlusOutlined style={data.openTill.incInpt} type="plus" onClick={() => onFieldButton(index, "plus")} />
                        </div>
                      </Col>
                      <Col span={7} style={data.openTill.colAlign}>
                        <Input
                          tabIndex={-1}
                          style={data.openTill.totalInput}
                          readOnly={true}
                          value={`${currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""} ${denom.amount} ${
                            currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""
                          }`}
                        />
                      </Col>
                    </Row>
                  ))}
                  <span tabIndex={0}></span>
                </Scrollbars>
              </Row>
            </div>
          </div>
          <div
            style={{
              width: "32.5%",
              paddingLeft: "2.3%",
              position: "relative",
            }}
          >
            <div style={{ ...data.openTill.opentillRightCard }}>
              <p style={data.openTill.emptyP} />
              <p style={data.openTill.emptyP}>Opened On</p>
              <div
                style={{
                  marginBottom: "0.5vh",
                  marginTop: "-6px",
                  marginTop: "1vh",
                }}
              >
                <span style={data.openTill.currentDate}>{currentDate}</span>
                <span style={data.openTill.displayClock}>{displayClock}</span>
              </div>
              <Form style={{ fontSize: "1vw" }}>
                <p
                  style={{
                    marginBottom: "1vh",
                    fontSize: "1.2em",
                    fontFamily: "Inter",
                    fontWeight: "500",
                    letterSpacing: "0px",
                    color: "#0F0718",
                    marginTop: "1vh",
                  }}
                >
                  Transactions
                </p>
                <Scrollbars className="closeTillScroll2">
                  <Form.Item
                    style={{
                      marginBottom: "0.8vh",
                      display: reconstructedObject ? (reconstructedObject.OpeningAmount === "Y" ? "block" : "none") : "block",
                    }}
                  >
                    <p style={data.openTill.transactionsAmtClose}>Opening Amount</p>
                    <Input
                      value={`${currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""} ${parseFloat(totalOpeningAmount).toFixed(
                        currencyType.stdPrecision
                      )} ${currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}`}
                      readOnly={true}
                      // className="transactionAmtInputClose"
                      className="transactionAmtInput"
                      style={{ height: "5.5vh" }}
                    />
                  </Form.Item>
                  <Form.Item
                    style={{
                      marginBottom: "0.8vh",
                      fontSize: "1vw",
                      display: reconstructedObject ? (reconstructedObject.SalesAmount === "Y" ? "block" : "none") : "block",
                    }}
                  >
                    <p style={data.openTill.transactionsAmtClose}>Cash Sale Amount</p>
                    <Input
                      value={`${currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""} ${parseFloat(cashSaleAmount).toFixed(currencyType.stdPrecision)} ${
                        currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""
                      }`}
                      readOnly={true}
                      className="transactionAmtInput"
                      style={{ height: "5.5vh", marginTop: "1vh" }}
                    />
                  </Form.Item>
                  <Form.Item
                    style={{
                      marginBottom: "0.8vh",
                      fontSize: "1vw",
                      display: reconstructedObject ? (reconstructedObject.SalesReturnAmount === "Y" ? "block" : "none") : "block",
                    }}
                  >
                    <p style={data.openTill.transactionsAmtClose}>Cash Sale Return Amount</p>
                    <Input
                      value={`${currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""} ${parseFloat(Math.abs(cashReturnAmount)).toFixed(
                        currencyType.stdPrecision
                      )} ${currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}`}
                      readOnly={true}
                      className="transactionAmtInput"
                      style={{ height: "5.5vh", marginTop: "1vh" }}
                    />
                  </Form.Item>
                  {/* my changes */}
                  <Form.Item
                    style={{
                      marginBottom: "0.8vh",
                      display: reconstructedObject ? (reconstructedObject.CashIn === "Y" ? "block" : "none") : "block",
                    }}
                  >
                    <p style={data.openTill.transactionsAmtClose}>Cash In</p>
                    <Input
                      value={`${currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""} ${parseFloat(cashValues.cashIn).toFixed(
                        currencyType.stdPrecision
                      )} ${currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}`}
                      readOnly={true}
                      className="transactionAmtInput"
                      style={{ height: "5.5vh" }}
                    />
                  </Form.Item>
                  <Form.Item
                    style={{
                      marginBottom: "0.8vh",
                      display: reconstructedObject ? (reconstructedObject.CashOut === "Y" ? "block" : "none") : "block",
                    }}
                  >
                    <p style={data.openTill.transactionsAmtClose}>Cash Out</p>
                    <Input
                      value={`${currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""} ${parseFloat(cashValues.cashOut).toFixed(
                        currencyType.stdPrecision
                      )} ${currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}`}
                      readOnly={true}
                      className="transactionAmtInput"
                      style={{ height: "5.5vh" }}
                    />
                  </Form.Item>
                  <Form.Item
                    style={{
                      marginBottom: "0.8vh",
                      display: reconstructedObject ? (reconstructedObject.PettyCashIn === "Y" ? "block" : "none") : "block",
                    }}
                  >
                    <p style={data.openTill.transactionsAmtClose}>Petty Cash In</p>
                    <Input
                      value={`${currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""} ${parseFloat(cashValues.pettCashIn).toFixed(
                        currencyType.stdPrecision
                      )} ${currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}`}
                      readOnly={true}
                      className="transactionAmtInput"
                      style={{ height: "5.5vh" }}
                    />
                  </Form.Item>
                  <Form.Item
                    style={{
                      marginBottom: "0.8vh",
                      display: reconstructedObject ? (reconstructedObject.pettyCashOut === "Y" ? "block" : "none") : "block",
                    }}
                  >
                    <p style={data.openTill.transactionsAmtClose}>Petty Cash Out</p>
                    <Input
                      value={`${currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""} ${parseFloat(cashValues.pettCashOut).toFixed(
                        currencyType.stdPrecision
                      )} ${currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}`}
                      readOnly={true}
                      className="transactionAmtInput"
                      style={{ height: "5.5vh" }}
                    />
                  </Form.Item>
                  <Form.Item
                    style={{
                      marginBottom: "0.8vh",
                      fontSize: "1vw",
                      display: reconstructedObject ? (reconstructedObject.ExpectedCash === "Y" ? "block" : "none") : "block",
                    }}
                  >
                    <p style={data.openTill.transactionsAmtClose}>Expected Cash</p>
                    <Input
                      value={`${currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""} ${parseFloat(cashExpectedAmount).toFixed(
                        currencyType.stdPrecision
                      )} ${currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}`}
                      readOnly={true}
                      className="transactionAmtInput"
                      style={{ height: "5.5vh", marginTop: "1vh" }}
                    />
                  </Form.Item>
                  <Form.Item style={{ marginBottom: "0.8vh", fontSize: "1vw" }}>
                    <p style={data.openTill.transactionsAmtClose}>Actual Cash</p>
                    <Input
                      value={`${currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""} ${parseFloat(totalAmount).toFixed(currencyType.stdPrecision)} ${
                        currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""
                      }`}
                      readOnly={true}
                      className="transactionAmtInput"
                      style={{ height: "5.5vh", marginTop: "1vh" }}
                    />
                  </Form.Item>
                </Scrollbars>
              </Form>
              <Row
                style={{
                  fontSize: "1vw",
                  position: "absolute",
                  bottom: 0,
                  width: "94%",
                }}
              >
                <Button ref={cancelBtnRef} style={data.openTill.btnCancel}>
                  <Link to={"/pos"}>Back</Link>
                </Button>
                <Button ref={confirmBtnRef} style={data.openTill.btnConfirm} onClick={excuteCloseTill}>
                  Next
                </Button>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CloseTill;
