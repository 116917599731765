import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col, Input, Button, message } from "antd";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { clock } from "../../utility/clock";
import { v4 as uuidv4 } from "uuid";
import POSLogo from "../../assets/images/icon.png";
import Axios from "axios";
import db from "../../database";
import data from "../../constants/opentillClosetill.json";
import "../style.css";
import _ from "lodash";
import { getOAuthHeaders } from "../../constants/oAuthValidation";

const OpenTillComponent = () => {
  const history = useHistory();
  const [openTillForm] = Form.useForm();
  const serverUrl = process.env.REACT_APP_serverUrl;
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const currenciesList = tillData.tillAccess.csBunit.currencies;
  const [inputAmount, setInputAmount] = useState();
  const [noteValue, setNoteValue] = useState("");
  const authHeaders = getOAuthHeaders();
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));
  const amountRef = useRef(null);

  useEffect(() => {
    amountRef.current.focus();
    const tillSession = localStorage.getItem("tillSession");
    if (tillSession) {
      if (JSON.parse(tillSession).tillStatus === "open") history.push("/pos");
    } else {
      db.tillRegistrations.toArray().then((reg) => {
        if (reg.length > 0 && reg[0]?.tillAccess?.length > 0) {
          const curList = reg[0].tillAccess[0].csBunit.csCurrency;
          const csCurrencyId = curList.csCurrencyId;
          localStorage.setItem("csCurrencyId", csCurrencyId);
        }
      });
    }
  }, []);

  const [denominations, setDenominations] = useState([]);

  useEffect(() => {
    const modifiedCurrenciesList = currenciesList[0].denominations?.map((denomItem, denomIndex) => ({
      ...denomItem,
      count: 0,
      amount: 0,
    }));
    setDenominations([...modifiedCurrenciesList]);
  }, []);

  const onFieldInput = (e) => {
    let amountValue = posConfig.showDayOpeningAmount === "Y" ? inputAmount : 0;
    const denominationValue = parseInt(amountValue);
    const denominationAmount = denominationValue;
    if (denominations && denominations.length > 0) {
      denominations[0].count = 1;
      denominations[0].amount = isNaN(denominationAmount) ? 0 : denominationAmount;
    }
    setDenominations([...denominations]);
    setInputAmount(0);
    excuteOpenTill(amountValue, denominations);
  };

  const excuteOpenTill = async (inputAmount, denominationsData) => {
    if (authHeaders && authHeaders.access_token) {
      const tillSessionId = uuidv4().replace(/-/g, "").toUpperCase();

      const tillData = JSON.parse(localStorage.getItem("tillData")) || {};
      const userData = JSON.parse(localStorage.getItem("userData")) || {};
      const posConfig = JSON.parse(localStorage.getItem("posConfig")) || {};
      const tillAccess = tillData.tillAccess || {};
      const cwrTill = tillAccess.cwrTill || {};
      const cashToKeep = cwrTill.cashToKeep || 0;

      // Opening and closing cash logic
      const openingCash = parseFloat(inputAmount) >= 0 ? inputAmount + cashToKeep : cashToKeep;

      const openTillData = {
        tillSessionId,
        tillStatus: "open",
        openingTime: moment().format("YYYY-MM-DD HH:mm:ss"),
        closingTime: null,
        openingCashDenominations: denominationsData,
        closingCashDenominations: null,
        openingCash,
        closingCash: 0,
        totalOpeningAmount: openingCash,
        totalClosingExpectedAmount: 0,
        totalClosingTransactions: 0,
        totalClosingAmount: 0,
        note: noteValue || "",
        user: userData.user || "",
      };

      localStorage.setItem("tillSession", JSON.stringify(openTillData));

      if (posConfig.DON === "Y") {
        const timeMark = moment().format("YYYY-MM-DD HH:mm:ss");
        const currentDate = new Date().toLocaleDateString("zh-Hans-CN");
        const trxId = uuidv4().replace(/-/g, "").toUpperCase();
        const dayOpeningArray = [
          `{
          type: "DON",
          action: "LOG",
          description: "${timeMark}",
          date: "${currentDate}",
          time: "${timeMark}",
          orderNo: "",
          remarks: "",
          transactionId: "${trxId}",
          status: "SCS",
          duration: null
        }`,
        ];

        try {
          await Axios({
            url: serverUrl,
            method: "POST",
            data: {
              query: `mutation {
              upsertPOSLog(order: {
                tillId: "${cwrTill.cwrTillID}",
                userId: "${tillAccess.csUserId}",
                bUnitId: "${tillAccess.csBunit.csBunitId}",
                lines: [${dayOpeningArray}]
              }) {
                status
                message
              }
            }`,
            },
            headers: {
              "Content-Type": "Application/json",
              Authorization: `${authHeaders.access_token}`,
            },
          });
        } catch (error) {
          console.error("Error logging POS action:", error);
        }
      }

      const index = tillAccess.csBunit?.paymentMethodList?.findIndex((payment) => payment.name.toLowerCase() === "cash") || -1;
      if (index === -1) {
        console.error("Cash payment method not found.");
        return;
      }

      const taxInfoData = {
        cwrTillID: cwrTill.cwrTillID,
        csbunitID: tillAccess.csBunit.csBunitId,
        csclientID: tillAccess.csClientId,
        created: moment().format("YYYY-MM-DD HH:mm:ss"),
        createdby: tillAccess.csUserId,
        updated: moment().format("YYYY-MM-DD HH:mm:ss"),
        updatedby: tillAccess.csUserId,
        storeDailyOpsTillid: null,
        tillCash: [
          {
            cwrTillCashId: uuidv4().replace(/-/g, "").toUpperCase(),
            date: moment().format("YYYY-MM-DD"),
            finPaymentmethodId: tillAccess.csBunit.paymentMethodList[index].finPaymentmethodId,
            finFinancialAccount2Id: null,
            opening: parseFloat(inputAmount) >= 0 ? inputAmount : 0,
            notes: noteValue || null,
            sales: 0,
            netsales: 0,
            cashin: 0,
            cashout: 0,
            retainAmt: 0,
            closing: 0,
            returns: 0,
            iscash: 1,
            isclose: 0,
            pettyCashIn: 0,
            pettyCashOut: 0,
            cashInOut: [],
            cashEvents: [],
          },
        ],
      };

      const stringifiedData = JSON.stringify(taxInfoData);
      const newStringifiedFields = stringifiedData.replace(/\\"/g, '\\"');
      const newStringRequest = JSON.stringify(newStringifiedFields);

      const paramsInput = {
        query: `mutation {
        upsertTill(tillInfo:${newStringRequest}) {
          status
          message
          cwrTillID
          tillCash {
            cwrTillCashId
            date
            finPaymentmethodId
            opening
            sales
            netsales
            cashin
            cashout
            retainAmt
            closing
            returns
            iscash
            notes
            isclose
            storeDailyOpsTillid
            cashEvents {
              cwrCashEventsID
              amount
              expected
              diff
              transactionCount
              type
              description
              cashEventDetails {
                cwrCashEventdetailsID
                count
                amount
                denomination
              }
            }
          }
        }
      }`,
      };

      try {
        const response = await Axios({
          url: serverUrl,
          method: "POST",
          data: paramsInput,
          headers: {
            "Content-Type": "Application/json",
            Authorization: `${authHeaders.access_token}`,
          },
        });

        if (response.data.data.upsertTill.status === "200") {
          localStorage.setItem("tillCash", JSON.stringify(response.data.data.upsertTill.tillCash));
          localStorage.setItem("storeDailyOpsTillid", response.data.data.upsertTill?.tillCash[0]?.storeDailyOpsTillid);
          history.push("/pos");
        } else {
          message.error(response.data.data.upsertTill.message);
          localStorage.removeItem("tillSession");
        }
      } catch (error) {
        console.error("Error updating till data:", error);
        message.error("Failed to open the till.");
        localStorage.removeItem("tillSession");
      }
    } else {
      console.error("Missing or invalid auth headers.");
    }
  };

  let reconstructedObject;
  const rawtillAccessMeta = tillData?.tillAccess?.tillAccessMeta;

  if (rawtillAccessMeta) {
    const rawDataArray = tillData?.tillAccess?.tillAccessMeta;

    // Find the object with the key "Open Till"
    const openTillObject = rawDataArray.find((item) => item.key === "Open Till");

    // Access the value property of the found object
    const openTillValue = openTillObject?.value;

    if (openTillValue !== undefined) {
      // Clean up the string (remove extra characters)
      const cleanedData = openTillValue.replace(/[{}"]/g, "");

      // Split the string into key-value pairs
      const keyValuePairs = cleanedData.split(",");

      // Create an object from key-value pairs
      reconstructedObject = {};
      keyValuePairs.forEach((pair) => {
        // Check if the pair contains a colon
        if (pair.includes(":")) {
          const [key, value] = pair.split(":");

          // Check if both key and value are not undefined before trimming
          if (key !== undefined && value !== undefined) {
            reconstructedObject[key.trim()] = value.trim();
          }
        }
      });
    } else {
      // Handle the case where openTillValue is undefined
      console.error("openTillValue is undefined");
    }
  }

  return (
    <div style={data.openTill.mainContainer}>
      <Row align="center" justify="center" style={{ paddingTop: "2vh" }}>
        <Col span={12}>
          <Row style={{ height: "6vh", padding: "1% 0 24.5% 0%" }}>
            <Col span={18} offset={1} style={{ display: "flex", alignItems: "center" }}>
              <img src={POSLogo} style={data.openTill.header.menuIcon} alt="" />
              <label style={{ fontSize: "2vw", color: "#000000", marginLeft: "5%", letterSpacing: "0.36px", opacity: 1, fontWeight: "600", textAlign: "center" }}>Open Till</label>
            </Col>
          </Row>
          <Row style={{ paddingTop: "1vh" }}>
            <Col offset={4} span={18} style={{ display: "flex", flexDirection: "column" }} id="step1">
              <Form form={openTillForm} name="oprnTillForm">
                <Form.Item style={{ width: "100%" }} name="amount" id="step1">
                  <p style={{ marginBottom: "0%", fontSize: "2.5vh", fontFamily: "Inter", fontWeight: "600", letterSpacing: "0px", color: "#000000" }}>Amount</p>
                  <Input
                    placeholder={
                      currenciesList[0].symbolRightSide === "N"
                        ? `${currenciesList[0].currSymbol}0`
                        : currenciesList[0].symbolRightSide === "Y"
                        ? `0${currenciesList[0].currSymbol}`
                        : ""
                    }
                    onChange={(e) => {
                      setInputAmount(e.target.value);
                    }}
                    ref={amountRef}
                    value={inputAmount}
                    className="transactionAmtInput"
                    style={{ marginBottom: "3vh", height: "8.5vh" }}
                  />
                </Form.Item>
                <Form.Item style={{ width: "100%" }} name="note">
                  <p style={{ marginBottom: "0%", fontSize: "2.5vh", fontFamily: "Inter", fontWeight: "600", letterSpacing: "0px", color: "#000000" }}>Note</p>
                  <Input
                    placeholder="Enter note"
                    onChange={(e) => setNoteValue(e.target.value)}
                    className="transactionAmtInput"
                    style={{ marginBottom: "1.7vh", height: "8.5vh" }}
                  />
                </Form.Item>
                <Button
                  id="step3"
                  htmlType="submit"
                  onClick={onFieldInput}
                  style={{ width: "100%", height: "8.5vh", backgroundColor: "#A4CD38", color: "#fff", marginTop: "5vh", fontSize: "3vh", fontWeight: "600" }}
                >
                  Open Till
                </Button>
              </Form>
            </Col>
          </Row>
        </Col>
        <Col span={12} style={{ paddingRight: "5.5%", paddingTop: "1.7%", paddingLeft: "1.7%" }}>
          <div id="step2" style={{ backgroundColor: "#F3F4F9", height: "88vh", borderRadius: "5px" }}>
            <p style={{ fontSize: "2.5vh", fontWeight: "600", paddingTop: "2.5vh", paddingLeft: "2vw" }}>Last Closure Details</p>
            <Row>
              <Col span={11} offset={1}>
                <p style={data.openTill.transactionsAmt1}>Outlet</p>{" "}
              </Col>
              <Col span={11}>
                <p style={data.openTill.transactionsAmtRight1}>{tillData.tillAccess.csBunit.name}</p>
              </Col>
              <Col span={11} offset={1}>
                <p style={data.openTill.transactionsAmt1}>Register</p>{" "}
              </Col>
              <Col span={11}>
                <p style={data.openTill.transactionsAmtRight1}>{tillData.tillAccess.cwrTill.till}</p>
              </Col>
              <Col style={{ display: reconstructedObject ? (reconstructedObject.OpeningAmount === "Y" ? "block" : "none") : "block" }} span={11} offset={1}>
                <p style={data.openTill.transactionsAmt1}>Opening Amount</p>{" "}
              </Col>
              <Col style={{ display: reconstructedObject ? (reconstructedObject.OpeningAmount === "Y" ? "block" : "none") : "block" }} span={11}>
                <p style={data.openTill.transactionsAmtRight1}>
                  {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                  {inputAmount?.length <= 0 ? 0 : inputAmount}
                  {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                </p>
              </Col>
              <Col style={{ display: reconstructedObject ? (reconstructedObject.CashIn === "Y" ? "block" : "none") : "block" }} span={11} offset={1}>
                <p style={data.openTill.transactionsAmt1}>Cash in</p>{" "}
              </Col>
              <Col style={{ display: reconstructedObject ? (reconstructedObject.CashIn === "Y" ? "block" : "none") : "block" }} span={11}>
                <p style={data.openTill.transactionsAmtRight1}>
                  {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}0{currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                </p>
              </Col>
              <Col style={{ display: reconstructedObject ? (reconstructedObject.CashOut === "Y" ? "block" : "none") : "block" }} span={11} offset={1}>
                <p style={data.openTill.transactionsAmt1}>Cash Out</p>{" "}
              </Col>
              <Col style={{ display: reconstructedObject ? (reconstructedObject.CashOut === "Y" ? "block" : "none") : "block" }} span={11}>
                <p style={data.openTill.transactionsAmtRight1}>
                  {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}0{currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                </p>
              </Col>
              <Col style={{ display: reconstructedObject ? (reconstructedObject.PettyCashIn === "Y" ? "block" : "none") : "block" }} span={11} offset={1}>
                <p style={data.openTill.transactionsAmt1}>Petty Cash in</p>{" "}
              </Col>
              <Col style={{ display: reconstructedObject ? (reconstructedObject.PettyCashIn === "Y" ? "block" : "none") : "block" }} span={11}>
                <p style={data.openTill.transactionsAmtRight1}>
                  {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}0{currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                </p>
              </Col>
              <Col style={{ display: reconstructedObject ? (reconstructedObject.pettyCashOut === "Y" ? "block" : "none") : "block" }} span={11} offset={1}>
                <p style={data.openTill.transactionsAmt1}>Petty Cash Out</p>{" "}
              </Col>
              <Col style={{ display: reconstructedObject ? (reconstructedObject.pettyCashOut === "Y" ? "block" : "none") : "block" }} span={11}>
                <p style={data.openTill.transactionsAmtRight1}>
                  {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}0{currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                </p>
              </Col>
              <Col style={{ display: reconstructedObject ? (reconstructedObject.ExpectedCash === "Y" ? "block" : "none") : "block" }} span={11} offset={1}>
                <p style={data.openTill.transactionsAmt1}>Expected Cash </p>{" "}
              </Col>
              <Col style={{ display: reconstructedObject ? (reconstructedObject.ExpectedCash === "Y" ? "block" : "none") : "block" }} span={11}>
                <p style={data.openTill.transactionsAmtRight1}>
                  {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}0{currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                </p>
              </Col>
              <Col span={11} offset={1}>
                <p style={data.openTill.transactionsAmt1}>Actual Cash</p>{" "}
              </Col>
              <Col span={11}>
                <p style={data.openTill.transactionsAmtRight1}>
                  {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}0{currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                </p>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default OpenTillComponent;
