import db from "../../../database";
import { message, Modal } from "antd";
import i18next from "i18next";
import NotFoundImg from "../../../assets/images/NotFoundImg.svg";
import { getOAuthHeaders } from "../../../constants/oAuthValidation";
import Axios from "axios";

const authHeaders = getOAuthHeaders();

export const barcodeScaner = async (
  data,
  tillData,
  tillLayout,
  addDefinedProduct,
  setBatchSetAvailable,
  setDisplayBatchSelection,
  setLayoutType,
  setIsProductsVisible,
  setProductsData,
  chackBarcodeFlag,
  setSelectedProductInCart,
  setProductSearchInput,
  productSearchInputRef,
  cart
) => {
  const t = i18next.t;
  try {
    // Early return if no data is provided
    if (!data) return;

    // RFID scan for 24-character codes
    if (data.length === 24) {
      return rfidScaner(
        data,
        tillData,
        tillLayout,
        addDefinedProduct,
        setBatchSetAvailable,
        setDisplayBatchSelection,
        setLayoutType,
        setIsProductsVisible,
        setProductsData,
        chackBarcodeFlag,
        setSelectedProductInCart,
        setProductSearchInput,
        productSearchInputRef,
        cart
      );
    }

    // Check if we should use scan configurations or default to product search
    const shouldUseConfig = tillData?.posScanConfigs?.length > 0 && !chackBarcodeFlag;

    if (shouldUseConfig) {
      return await barcodeScanFromConfig(
        data,
        tillData,
        tillLayout,
        addDefinedProduct,
        setBatchSetAvailable,
        setDisplayBatchSelection,
        setLayoutType,
        setIsProductsVisible,
        setProductsData,
        setSelectedProductInCart,
        setProductSearchInput,
        productSearchInputRef
      );
    }

    // Product search
    const products = await db.products
      .filter((product) => product.name?.toLowerCase().includes(data.toLowerCase()) || product.upcIndex === data || product.upc === data || product.value === data)
      .limit(100)
      .toArray();

    // Handle product search results
    if (!products || products.length === 0) {
      await upcPricingRule(data, t, addDefinedProduct, setProductSearchInput, productSearchInputRef);
      return;
    }

    // Single product found
    if (products.length === 1) {
      const product = products[0];

      // Reset layout type if needed
      if (tillLayout === 3) {
        localStorage.setItem("layoutType", 1);
        setLayoutType(1);
        const customEvent = new CustomEvent("customStorageChange", {
          detail: { key: "layoutType", newValue: 1 },
        });
        window.dispatchEvent(customEvent);
      }

      // Handle batched products
      if (product.batchedProduct === "Y" && product.batchedForSale === "Y") {
        await handleBatchedProduct(product, data, addDefinedProduct, setBatchSetAvailable, setDisplayBatchSelection);
      } else {
        // Handle non-batched product
        const modifiedQty = product.weight >= 0 ? product.weight + 1 : 1;
        addDefinedProduct(product, product.upc, null, null, product.sunitprice, true, modifiedQty, true);
      }
    }
    // Multiple products found
    else if (products.length > 1) {
      setIsProductsVisible(true);
      setProductsData(products);
    }
  } catch (error) {
    console.error("Barcode scanning error:", error);
    // Fallback to UPC pricing rule in case of any error
    await upcPricingRule(data, t, addDefinedProduct, setProductSearchInput, productSearchInputRef);
  }
};

const handleBatchedProduct = async (product, data, addDefinedProduct, setBatchSetAvailable, setDisplayBatchSelection) => {
  try {
    // Ensure product.mBatch is defined and is an array
    if (!product?.mBatch || !Array.isArray(product.mBatch)) {
      console.error("No batches available for this product");
      return; // Exit if mBatch is not available or not an array
    }

    // Find batch by barcode
    const batchProductIndex = product.mBatch.findIndex((bp) => bp.batchno?.toLowerCase() === data.toLowerCase());

    // Single batch with exact match
    if (batchProductIndex >= 0 && product.multiPrice === "N") {
      const batch = product.mBatch[batchProductIndex];
      if (!batch?.upc || !batch?.batchno || !batch?.mBatchId || !batch?.price) {
        console.error("Invalid batch data", batch);
        return; // Exit if any required batch properties are missing
      }
      return addDefinedProduct(product, batch.upc, batch.batchno, batch.mBatchId, batch.price);
    }

    // Only one batch available
    if (product.mBatch.length === 1) {
      const batch = product.mBatch[0];
      if (!batch?.upc || !batch?.batchno || !batch?.mBatchId || !batch?.price) {
        console.error("Invalid batch data", batch);
        return; // Exit if any required batch properties are missing
      }
      return addDefinedProduct(product, batch.upc, batch.batchno, batch.mBatchId, batch.price);
    }

    // Multiple batches - prepare product set
    const productSet = product.mBatch
      .map((batchItem) => {
        // Ensure batchItem and necessary properties are defined
        if (!batchItem?.upc || !batchItem?.batchno || !batchItem?.mBatchId || batchItem?.price === undefined) {
          console.error("Invalid batch item data", batchItem);
          return null; // Skip invalid batch item
        }

        const modifiedProduct = { ...product };

        // Tax calculation logic with valid data checks
        if (modifiedProduct?.overRideTax === "Y" && batchItem?.price <= modifiedProduct?.overRideCondition) {
          const originalPrice = batchItem.price - batchItem.price * (100 / (100 + modifiedProduct.taxRate));
          const taxedPrice = originalPrice + (originalPrice * modifiedProduct.contraRate) / 100;

          modifiedProduct.cTaxId = modifiedProduct.contraTaxId;
          modifiedProduct.taxRate = modifiedProduct.contraRate;
          batchItem.price = taxedPrice;
        }

        return createProductDefinition(modifiedProduct, batchItem);
      })
      .filter(Boolean); // Filter out null values

    // Check if productSet has valid entries
    if (productSet.length > 0) {
      setBatchSetAvailable(productSet);
      setDisplayBatchSelection(true);
    }
  } catch (error) {
    console.error("Batched product handling error:", error);
  }
};

// Helper function to create product definition
const createProductDefinition = (product, batchItem) => ({
  batchno: batchItem?.batchno ?? "",
  description: product?.description ?? "",
  discount: 0,
  discountName: "",
  imageurl: product?.imageurl ?? "",
  isDecimal: product?.isDecimal ?? false,
  isManualQty: product?.isManualQty ?? false,
  isPromoApplicable: product?.isPromoApplicable ?? false,
  isReturn: false,
  mBatchId: batchItem?.mBatchId ?? null,
  mPricingruleId: null,
  name: product?.name ?? "",
  nettotal: 0,
  primaryOrderLine: null,
  productId: product?.mProductId ?? "",
  realPrice: product?.cTaxId ?? 0,
  listPrice: product?.slistprice ?? 0,
  sunitprice: product?.sunitprice ?? 0,
  returnQty: null,
  salePrice: product?.sunitprice ?? 0,
  mrpPrice: batchItem?.listPrice ?? 0,
  stock: product?.onhandQty ?? 0,
  taxCategory: product?.taxCategory ?? "",
  taxName: product?.taxName ?? "",
  taxFlag: product?.taxFlag ?? "N",
  tax: product?.cTaxId ?? 0,
  taxAmount: 0,
  taxRate: product?.taxRate ?? 0,
  uom: product?.csUomId ?? "",
  uom_name: product?.uomName ?? "",
  isDecimalQty: product?.uomData?.[0]?.decimal === "Y" ?? false,
  isQtyDesimal: product?.uomData?.[0]?.stdprecision ?? 2,
  upc: batchItem?.upc ?? "",
  value: product?.value ?? 0,
  weight: 0,
  shortDescription: product?.shortDescription ?? "",
  hsncode: product?.hsncode ?? "",
  csBunitId: product?.csBunitId ?? "",
  mProductCategoryId: product?.mProductCategoryId ?? "",
  productManufacturerId: product?.productManufacturerId ?? "",
  productBrandId: product?.productBrandId ?? "",
  batchedProduct: product?.batchedProduct ?? false,
  batchedForSale: product?.batchedForSale ?? false,
  batchedForStock: product?.batchedForStock ?? false,
  multiPrice: product?.multiPrice ?? "N",
  shelfLife: product?.shelfLife ?? "",
  addNewLine: product?.addNewLine || "N",
});

const getProduct = (
  formula,
  newData,
  tillData,
  data,
  tillLayout,
  addDefinedProduct,
  setBatchSetAvailable,
  setDisplayBatchSelection,
  setLayoutType,
  setIsProductsVisible,
  setProductsData,
  setSelectedProductInCart,
  setProductSearchInput,
  productSearchInputRef
) => {
  const productValue = newData.substr(Number(formula.property["product.value"]?.position - 1), Number(formula.property["product.value"]?.length));
  const operators = ["+", "-", "/", "*"];
  const operatorFunctions = {
    "+": (a, b) => a + b,
    "-": (a, b) => a - b,
    "*": (a, b) => a * b,
    "/": (a, b) => a / b,
  };
  db.products
    .where("value")
    .equalsIgnoreCase(productValue)
    .toArray()
    .then(async (product) => {
      console.log(product, productValue);
      const uomData = product[0]?.uomData[0];
      let orderNo = "";
      const currenciesList = tillData.tillAccess.csBunit.currencies;
      let cartlineQty;
      if (formula.property["cartline.qty"] !== undefined) {
        if (typeof formula.property["cartline.qty"] === "number") {
          cartlineQty = formula.property["cartline.qty"];
        } else if (typeof formula.property["cartline.qty"] === "string") {
          const foundOperator = operators.find((op) => formula.property["cartline.qty"].includes(op));
          const splitArr = formula.property["cartline.qty"].split(foundOperator);
          let qtyArr = splitArr.map((splitItem) => {
            let value;
            if (splitItem.includes("cartline")) {
              if (splitItem.includes("qty")) {
                if (typeof formula.property["cartline.qty"] === "number") {
                  value = formula.property["cartline.qty"];
                } else if (typeof formula.property["cartline.qty"] === "object") {
                  value = parseFloat(
                    (
                      Number(newData.substr(Number(formula.property["cartline.qty"]?.position - 1), Number(formula.property["cartline.qty"]?.length))) /
                      (uomData && uomData?.decimal === "Y" ? Math.pow(10, uomData?.stdprecision) : 1000)
                    ).toFixed(uomData && uomData?.decimal === "Y" ? uomData?.stdprecision : 3)
                  );
                }
              } else if (splitItem.includes("total")) {
                if (typeof formula.property["cartline.total"] === "number") {
                  value = formula.property["cartline.total"];
                } else if (typeof formula.property["cartline.total"] === "object") {
                  value = parseFloat(
                    (
                      Number(newData.substr(Number(formula.property["cartline.total"]?.position - 1), Number(formula.property["cartline.total"]?.length))) /
                      (currenciesList[0]?.stdPrecision ? Math.pow(10, currenciesList[0].stdPrecision) : 100)
                    ).toFixed(currenciesList[0].stdPrecision ? currenciesList[0].stdPrecision : 2)
                  );
                }
              } else if (splitItem.includes("unitprice")) {
                if (typeof formula.property["cartline.unitprice"] === "number") {
                  value = formula.property["cartline.unitprice"];
                } else if (typeof formula.property["cartline.unitprice"] === "object") {
                  value = parseFloat(
                    (
                      Number(newData.substr(Number(formula.property["cartline.unitprice"]?.position - 1), Number(formula.property["cartline.unitprice"]?.length))) /
                      (currenciesList[0]?.stdPrecision ? Math.pow(10, currenciesList[0].stdPrecision) : 100)
                    ).toFixed(currenciesList[0].stdPrecision ? currenciesList[0].stdPrecision : 2)
                  );
                }
              }
            } else {
              value = product[0].sunitprice;
            }
            return value;
          });
          cartlineQty = operatorFunctions[foundOperator](qtyArr[0], qtyArr[1]);
        } else if (typeof formula.property["cartline.qty"] === "object") {
          cartlineQty = parseFloat(
            (
              Number(newData.substr(Number(formula.property["cartline.qty"]?.position - 1), Number(formula.property["cartline.qty"]?.length))) /
              (uomData && uomData?.decimal === "Y" ? Math.pow(10, uomData?.stdprecision) : 1000)
            ).toFixed(uomData && uomData?.decimal === "Y" ? uomData?.stdprecision : 3)
          );
        }
      }

      let cartlineUnitprice;
      if (formula.property["cartline.unitprice"] !== undefined) {
        if (typeof formula.property["cartline.unitprice"] === "number") {
          cartlineUnitprice = formula.property["cartline.unitprice"];
        } else if (typeof formula.property["cartline.unitprice"] === "string") {
          const foundOperator = operators.find((op) => formula.property["cartline.unitprice"].includes(op));
          const splitArr = formula.property["cartline.unitprice"].split(foundOperator);
          let unitpriceArr = splitArr.map((splitItem) => {
            let value;
            if (splitItem.includes("cartline")) {
              if (splitItem.includes("qty")) {
                if (typeof formula.property["cartline.qty"] === "number") {
                  value = formula.property["cartline.qty"];
                } else if (typeof formula.property["cartline.qty"] === "object") {
                  value = parseFloat(
                    (
                      Number(newData.substr(Number(formula.property["cartline.qty"]?.position - 1), Number(formula.property["cartline.qty"]?.length))) /
                      (uomData && uomData?.decimal === "Y" ? Math.pow(10, uomData?.stdprecision) : 1000)
                    ).toFixed(uomData && uomData?.decimal === "Y" ? uomData?.stdprecision : 3)
                  );
                }
              } else if (splitItem.includes("total")) {
                if (typeof formula.property["cartline.total"] === "number") {
                  value = formula.property["cartline.total"];
                } else if (typeof formula.property["cartline.total"] === "object") {
                  value = parseFloat(
                    (
                      Number(newData.substr(Number(formula.property["cartline.total"]?.position - 1), Number(formula.property["cartline.total"]?.length))) /
                      (currenciesList[0]?.stdPrecision ? Math.pow(10, currenciesList[0].stdPrecision) : 100)
                    ).toFixed(currenciesList[0].stdPrecision ? currenciesList[0].stdPrecision : 2)
                  );
                }
              } else if (splitItem.includes("unitprice")) {
                if (typeof formula.property["cartline.unitprice"] === "number") {
                  value = formula.property["cartline.unitprice"];
                } else if (typeof formula.property["cartline.unitprice"] === "object") {
                  value = parseFloat(
                    (
                      Number(newData.substr(Number(formula.property["cartline.unitprice"]?.position - 1), Number(formula.property["cartline.unitprice"]?.length))) /
                      (currenciesList[0]?.stdPrecision ? Math.pow(10, currenciesList[0].stdPrecision) : 100)
                    ).toFixed(currenciesList[0].stdPrecision ? currenciesList[0].stdPrecision : 2)
                  );
                }
              }
            } else {
              value = product[0].sunitprice;
            }
            return value;
          });
          cartlineUnitprice = operatorFunctions[foundOperator](unitpriceArr[0], unitpriceArr[1]);
        } else {
          cartlineUnitprice = parseFloat(
            (
              Number(newData.substr(Number(formula.property["cartline.unitprice"]?.position - 1), Number(formula.property["cartline.unitprice"]?.length))) /
              (currenciesList[0]?.stdPrecision ? Math.pow(10, currenciesList[0].stdPrecision) : 100)
            ).toFixed(currenciesList[0].stdPrecision ? currenciesList[0].stdPrecision : 2)
          );
        }
      }

      let cartlineTotal;
      if (formula.property["cartline.total"] !== undefined) {
        if (typeof formula.property["cartline.total"] === "number") {
          cartlineTotal = formula.property["cartline.total"];
        } else if (typeof formula.property["cartline.total"] === "string") {
          const foundOperator = operators.find((op) => formula.property["cartline.total"].includes(op));
          const splitArr = formula.property["cartline.total"].split(foundOperator);
          let totalArr = splitArr.map((splitItem) => {
            let value;
            if (splitItem.includes("cartline")) {
              if (splitItem.includes("qty")) {
                if (typeof formula.property["cartline.qty"] === "number") {
                  value = formula.property["cartline.qty"];
                } else if (typeof formula.property["cartline.qty"] === "object") {
                  value = parseFloat(
                    (
                      Number(newData.substr(Number(formula.property["cartline.qty"]?.position - 1), Number(formula.property["cartline.qty"]?.length))) /
                      (uomData && uomData?.decimal === "Y" ? Math.pow(10, uomData?.stdprecision) : 1000)
                    ).toFixed(uomData && uomData?.decimal === "Y" ? uomData?.stdprecision : 3)
                  );
                }
              } else if (splitItem.includes("total")) {
                if (typeof formula.property["cartline.total"] === "number") {
                  value = formula.property["cartline.total"];
                } else if (typeof formula.property["cartline.total"] === "object") {
                  value = parseFloat(
                    (
                      Number(newData.substr(Number(formula.property["cartline.total"]?.position - 1), Number(formula.property["cartline.total"]?.length))) /
                      (currenciesList[0]?.stdPrecision ? Math.pow(10, currenciesList[0].stdPrecision) : 100)
                    ).toFixed(currenciesList[0].stdPrecision ? currenciesList[0].stdPrecision : 2)
                  );
                }
              } else if (splitItem.includes("unitprice")) {
                if (typeof formula.property["cartline.unitprice"] === "number") {
                  value = formula.property["cartline.unitprice"];
                } else if (typeof formula.property["cartline.unitprice"] === "object") {
                  value = parseFloat(
                    (
                      Number(newData.substr(Number(formula.property["cartline.unitprice"]?.position - 1), Number(formula.property["cartline.unitprice"]?.length))) /
                      (currenciesList[0]?.stdPrecision ? Math.pow(10, currenciesList[0].stdPrecision) : 100)
                    ).toFixed(currenciesList[0].stdPrecision ? currenciesList[0].stdPrecision : 2)
                  );
                }
              }
            } else {
              value = product[0]?.sunitprice || 0;
            }
            return value;
          });
          cartlineTotal = operatorFunctions[foundOperator](totalArr[0], totalArr[1]);
        } else {
          cartlineTotal = parseFloat(
            (
              Number(newData.substr(Number(formula.property["cartline.total"]?.position - 1), Number(formula.property["cartline.total"]?.length))) /
              (currenciesList[0]?.stdPrecision ? Math.pow(10, currenciesList[0].stdPrecision) : 100)
            ).toFixed(currenciesList[0].stdPrecision ? currenciesList[0].stdPrecision : 2)
          );
        }
      }

      if (formula.property["production.orderNo"] !== undefined) {
        orderNo = newData.substr(Number(formula.property["production.orderNo"]?.position - 1), Number(formula.property["production.orderNo"]?.length));
      }

      if (formula.property["product.unitprice"] !== undefined) {
        if (formula.property["product.unitprice"]?.type === "event") {
          const workflowRules = await db.POSWorkFlowRules.toArray();
          // const approvers = await db.approvers.toArray();

          if (workflowRules && workflowRules.length > 0) {
            await Promise.all(
              workflowRules.map(async (res) => {
                if (res.eventId === "productPriceOnScan") {
                  const serverUrl = process.env.REACT_APP_serverUrl;
                  const CWCoreServicesUrl = process.env.REACT_APP_genericUrl;

                  if (res.apiSource === "APB") {
                    const value = { orderno: orderNo, value: productValue };
                    const newStringifiedFields = JSON.stringify(value).replace(/\\"/g, '\\"');
                    const newStringRequest = JSON.stringify(newStringifiedFields);
                    const apiBuilder = {
                      query: `mutation { executeAPIBuilder(apiBuilderId:"${res.apiEndPoint}", params: ${newStringRequest})} `,
                    };
                    const headers = {
                      "Content-Type": "application/json",
                      Authorization: `${authHeaders.access_token}`,
                    };

                    const apiBuilderResponse = await Axios.post(CWCoreServicesUrl, apiBuilder, { headers: headers });

                    if (apiBuilderResponse.data.data.executeAPIBuilder.length > 0) {
                      const response = JSON.parse(apiBuilderResponse.data.data.executeAPIBuilder);
                      cartlineUnitprice = parseFloat(response?.length > 0 ? response[0].sale_price.toFixed(currenciesList[0].stdPrecision || 2) : 0);
                    }
                  } else if (res.apiSource === "RES") {
                    console.log("-------------->Retail");
                  } else if (res.apiSource === "CAP") {
                    console.log("-------------->Core");
                  }
                }
              })
            );
          }
        }
      }
      if (product.length <= 0) {
        let chackBarcodeFlag = true;
        barcodeScaner(
          data,
          tillData,
          tillLayout,
          addDefinedProduct,
          setBatchSetAvailable,
          setDisplayBatchSelection,
          setLayoutType,
          setIsProductsVisible,
          setProductsData,
          chackBarcodeFlag,
          setSelectedProductInCart,
          setProductSearchInput,
          productSearchInputRef
        );
      } else {
        product[0].taxFlag = formula?.calculateTax || "N";
        if (product[0].taxFlag === "Y") {
          cartlineUnitprice = cartlineUnitprice ? cartlineUnitprice : product[0]?.sunitprice;
          let taxValue = (cartlineUnitprice * (product[0].taxRate / 100)) / (1 + product[0].taxRate / 100);
          cartlineUnitprice = parseFloat((cartlineUnitprice - taxValue).toFixed(currenciesList[0].stdPrecision ? currenciesList[0].stdPrecision : 2));
          product[0].sunitprice = cartlineUnitprice;
        }
        addDefinedProduct(product[0], product[0].upc, null, null, cartlineUnitprice || product[0].sunitprice, true, cartlineQty || 1, false, cartlineTotal);
      }
    });
};

const barcodeScanFromConfig = (
  data,
  tillData,
  tillLayout,
  addDefinedProduct,
  setBatchSetAvailable,
  setDisplayBatchSelection,
  setLayoutType,
  setIsProductsVisible,
  setProductsData,
  setSelectedProductInCart,
  setProductSearchInput,
  productSearchInputRef
) => {
  let newData = data.toString();
  let productFlag;
  let posConfig = tillData.posScanConfigs.filter((item) => {
    if (item.formula !== null) {
      if (item.scanTrxType === "PR" && item.barcodeLength === newData.length) {
        return item;
      }
    }
  });
  if (posConfig.length === 1) {
    let formula = JSON.parse(posConfig[0]?.formula);
    formula.calculateTax = posConfig[0]?.calculateTax || "N";
    getProduct(
      formula,
      newData,
      tillData,
      data,
      tillLayout,
      addDefinedProduct,
      setBatchSetAvailable,
      setDisplayBatchSelection,
      setLayoutType,
      setIsProductsVisible,
      setProductsData,
      setSelectedProductInCart,
      setProductSearchInput,
      productSearchInputRef
    );
    productFlag = true;
  } else {
    const posConfigItem = posConfig.filter((item) => data.startsWith(item.startsWith));
    if (posConfigItem.length > 0) {
      productFlag = true;
      let formula = JSON.parse(posConfigItem[0]?.formula);
      formula.calculateTax = posConfigItem[0]?.calculateTax || "N";
      getProduct(
        formula,
        newData,
        tillData,
        data,
        tillLayout,
        addDefinedProduct,
        setBatchSetAvailable,
        setDisplayBatchSelection,
        setLayoutType,
        setIsProductsVisible,
        setProductsData,
        setSelectedProductInCart,
        setProductSearchInput,
        productSearchInputRef
      );
    }
  }

  if (!productFlag) {
    let chackBarcodeFlag = true;
    barcodeScaner(
      data,
      tillData,
      tillLayout,
      addDefinedProduct,
      setBatchSetAvailable,
      setDisplayBatchSelection,
      setLayoutType,
      setIsProductsVisible,
      setProductsData,
      chackBarcodeFlag,
      setSelectedProductInCart,
      setProductSearchInput,
      productSearchInputRef
    );
  }
};

const rfidScaner = (
  data,
  tillData,
  tillLayout,
  addDefinedProduct,
  setBatchSetAvailable,
  setDisplayBatchSelection,
  setLayoutType,
  setIsProductsVisible,
  setProductsData,
  chackBarcodeFlag,
  setSelectedProductInCart,
  setProductSearchInput,
  productSearchInputRef,
  cart
) => {
  db.rfidData
    .where("tag_value")
    .equalsIgnoreCase(data)
    .toArray()
    .then((item) => {
      if (item.length > 0) {
        const scannedProductCode = item[0].product_code;
        const existingIndex = cart?.items?.findIndex((item) => item.value === scannedProductCode);
        if (existingIndex === -1) {
          barcodeScaner(
            item[0].product_code,
            tillData,
            tillLayout,
            addDefinedProduct,
            setBatchSetAvailable,
            setDisplayBatchSelection,
            setLayoutType,
            setIsProductsVisible,
            setProductsData,
            chackBarcodeFlag,
            setSelectedProductInCart,
            setProductSearchInput,
            productSearchInputRef,
            cart
          );
          db.rfidData.update(item[0].tag_value, { tag_status: "SO" });
          if (tillLayout === 3) {
            localStorage.setItem("layoutType", 1);
            setLayoutType(1);
            const customEvent = new CustomEvent("customStorageChange", {
              detail: { key: "layoutType", newValue: 1 },
            });
            window.dispatchEvent(customEvent);
          }
        } else {
          message.info("Product Already Scanned");
        }
      }
    });
};

const upcPricingRule = async (data, t, addDefinedProduct, setProductSearchInput, productSearchInputRef) => {
  const pr = await db.pricingRules.toArray();
  let productFlag = false;

  for (const ele of pr) {
    if (ele.upc === data) {
      const pro = await db.products.where("mProductId").equalsIgnoreCase(ele.mPricingXProducts[0].mProductId).toArray();

      if (pro.length > 0) {
        pro[0].upcPricingRule = ele.upc;
        productFlag = true;
        addDefinedProduct(pro[0], pro[0].upc, null, null, pro[0].sunitprice, true, ele.mPricingXProducts[0].quantity);
      }
    }
  }

  if (!productFlag) {
    // message.warning(`${t('Product not found')}`);
    Modal.info({
      icon: null,
      okButtonProps: { style: { display: "none" } },
      okText: "Go Back",
      content: (
        <div
          style={{
            fontSize: "18px",
            fontWeight: "500",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingLeft: 0,
            fontSize: "1vw",
          }}
        >
          <img src={NotFoundImg} alt="Not Found" style={{ width: "120px", height: "100px", marginBottom: "5px", marginTop: "10px" }} />
          <p style={{ margin: "5px 0", fontSize: "2em", fontWeight: "600", color: "#0F0718" }}>Product Not Found</p>
          <hr style={{ width: "70%", color: "rgba(146,144,152,0.5" }} />
          <p style={{ color: "#0F0718", fontWeight: 400, fontSize: "1.25em", padding: "15px 15px 0 15px" }}>
            The scanned product is not in the system. Please check the barcode or enter the item manually.
          </p>
        </div>
      ),
      style: {
        textAlign: "center",
        height: "50vh",
        width: "50%",
      },
      bodyStyle: {
        padding: "0",
      },
      closable: false,
      onOk: null,
    });

    setTimeout(() => {
      Modal.destroyAll();
    }, 1500);
  }
  setProductSearchInput("");
  if (productSearchInputRef?.current) {
    productSearchInputRef.current.clearInput();
  }
};
